@font-face {
    font-family: 'dosislight';
    src: url('#{$fonts}/dosis_light/fonts/dosis-light-webfont.woff2') format('woff2'),
         url('#{$fonts}/dosis_light/fonts/dosis-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'dosisregular';
    src: url('#{$fonts}/dosis_regular/fonts/dosis-regular-webfont.woff2') format('woff2'),
         url('#{$fonts}/dosis_regular/fonts/dosis-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'sweet_saltyregular';
    src: url('#{$fonts}/sweetsalty_regular/fonts/sweet-salty-regular-webfont.woff2') format('woff2'),
         url('#{$fonts}/sweetsalty_regular/fonts/sweet-salty-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'dosisbold';
    src: url('#{$fonts}/dosis_bold/fonts/dosis-bold-webfont.woff2') format('woff2'),
         url('#{$fonts}/dosis_bold/fonts/dosis-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'roboto_bolditalic';
    src: url('#{$fonts}/roboto_bolditalic/Roboto-BoldItalic.eot');
    src: url('#{$fonts}/roboto_bolditalic/Roboto-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('#{$fonts}/roboto_bolditalic/Roboto-BoldItalic.woff') format('woff'),
        url('#{$fonts}/roboto_bolditalic/Roboto-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'dosisextrabold';
    src: url('#{$fonts}/dosis_extrabold/fonts/dosis-extrabold-webfont.woff2') format('woff2'),
         url('#{$fonts}/dosis_extrabold/fonts/dosis-extrabold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
