.topo{
	position: relative;
	background: $bg-topo;

	&:before{
		content: "";

		display: block;
		width: 100%;
		height: 5px;

		margin-bottom: 15px;

		background: $before-topo;
	}

	&:after{
		content: "";

		position: absolute;
		top: 100%;
		left: 0;

		display: block;
		width: 100%;
		height: 25px;

		z-index: 19;

		background: $after-topo;



		@media (max-width: 500px){
			display: none;
		}
	}

	.logo{
		margin-bottom: 10px;
	}

	.navbar-toggle{
		border-color: #374b9d;
		color: #374b9d;
		position: relative;
		z-index: 20;
	}

	.navbar-nav{
		float: none;
		
		#menu{
			@media(min-width: $screen-sm-min){
				display: flex;
				justify-content: space-between;
				align-items: center;
			}

			li{
				position: relative;

				a{
					display: block;

					font-family: "dosislight";
					font-size: 20px;
					color: #686868;

					@media(max-width: $screen-xs-max){
						padding: 10px 15px;
					}

					figure{
						margin-bottom: 10px;

						@media(max-width: $screen-xs-max){
							display: none;
						}
					}

					.fa{
						position: absolute;
						top: 100%;
						left: 50%;

						transform: translateX(-50%);

						@media(max-width: $screen-xs-max){
							display: none;
						}
					}
				}

				&:hover{
					.submenu{
						max-height: initial;
					}
				}

				.submenu{
					@media(min-width: $screen-sm-min){
						position: absolute;
						top: 100%;
						left: 0;

						z-index: 119;

						max-height: 0;
						overflow: hidden;
					}

					border-radius: 5px;
					background: #E3E3E3;

					ul{
						margin: 18px 0;
						min-width: 147px;

						li{
							a{
								display: block;
								font-family: "dosisregular";
								font-size: 15px;
								color: #585858;

								padding: 10px 15px;
							}

							&:hover{
								background: #FFF;

								a{
									color: #E6754D;
								}
							}
						}
					}
				}
			}
		}
	}

	.redes-sociais{
		text-align: right;
		margin-top: 25px;
		
		a{
			display: inline-block;
			vertical-align: middle;
			margin: 0 5px;

			&:first-child{
				margin-left: 0;
			}

			&:last-child{
				margin-right: 0;
			}
		}
	}
}