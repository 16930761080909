@for $i from 0 through 20 {
	$m: $i * 5;

	.mgb-#{$m}{
		margin-bottom: #{$m}px;
	}

	.mgt-#{$m}{
		margin-top: #{$m}px;
	}

	.mgl-#{$m}{
		margin-left: #{$m}px;
	}

	.mgr-#{$m}{
		margin-right: #{$m}px;
	}

	.pdb-#{$m}{
		padding-bottom: #{$m}px;
	}

	.pdt-#{$m}{
		padding-top: #{$m}px;
	}

	.pdl-#{$m}{
		padding-left: #{$m}px;
	}

	.pdr-#{$m}{
		padding-right: #{$m}px;
	}

}

#msg-ie{
	.alert{
		border-radius:0;
	}
}

.no-roundnnes{
	border-radius:0;
}

.internas{
	// padding-bottom:140px;

	h1{
		font-family: "dosisextrabold";
		font-size: 50px;
		color: #374B9D;
	}
	
	h2{
		font-family: "dosisextrabold";
		font-size: 35px;
		color: #374b9d;

		margin-top: 0;
		margin-bottom: 10px;
	}
	
	h3{
		font-family: "dosisregular";
		font-size: 17px;
		color: #E9614B;
	}

	.content-carousel{
		position: relative;

		.pager{
			position: absolute;
			top: 50%;
			margin: 0;

			transform: translateY(-50%);

			&.pager-prev{
				right: calc(100% + 10px);
			}

			&.pager-next{
				left: calc(100% + 10px);
			}
		}
	}
}

.btn-enviar,
.btn-voltar{
	font-size:20px;
	min-width:190px;
}

a[data-lightbox]{
	cursor: zoom-in;
}


input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
}
