.wrapper{
	min-height:100vh;
}

html{
	overflow-x:hidden;
}

body{
	font-family: "dosisregular";
	font-size: 16px;
	color: #878787;
}

.container{
	max-width: 1170px;
}