$icons: (
		'br': -59px 0px 48px 33px,
		'en': 0px -43px 48px 33px,
		'es': 0px 0px 49px 33px,
		'ic-cliente': -45px -86px 33px 34px,
		'ic-facebook': -162px -43px 33px 33px,
		'ic-google': -88px -86px 34px 33px,
		'ic-instagram': -117px 0px 35px 35px,
		'ic-linkedin': -58px -43px 35px 33px,
		'ic-twitter': -162px -86px 33px 33px,
		'ic-vimeo': -162px 0px 34px 33px,
		'ic-whatsapp': 0px -131px 33px 33px,
		'ic-youtube': 0px -86px 35px 35px,
);

.icon{
	display:inline-block;
	background-image: url(#{$imgs}/sprite.png);
	background-repeat:no-repeat;
	vertical-align: middle;

	&.block{
		display:block;
	}
}

@mixin sprites($icons) {
	@each $icon,$data in $icons{
		.#{$icon}{
			width: nth($data,3);
			height: nth($data,4);
			background-position: #{nth($data,1)} #{nth($data,2)};
		}
	}
}

@include sprites($icons);