.carousel-fade {
    .carousel-inner {
        .item {
            transition-property: opacity;
        }
        
        .item,
        .active.left,
        .active.right {
            opacity: 0;
        }

        .active,
        .next.left,
        .prev.right {
            opacity: 1;
        }

        .next,
        .prev,
        .active.left,
        .active.right {
            left: 0;
            transform: translate3d(0, 0, 0);
        }
    }

    .carousel-control {
        z-index: 2;
    }
}


.noround{
    border-radius:0;
}

.img-responsive{
    display: inline-block;
}

.unlistenner{
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
}

.owl-nav{
    display: none;
}

.btn{
    min-width: 162px;
    padding: 12px 5px;
    border-radius: 25px;

    font-family: "dosisbold";
    font-size: 14px;
}

.btn-yellow{
    color: #000;
    background: #FFC900;

    &:hover,
    &:focus{
        color: #000;
        background: darken(#FFC900, 10%);
    }
}

.btn-black{
    color: #FFF;
    background: #000;
    &:hover,
    &:focus{
        color: #FFF;
        background: lighten(#000, 10%);
    }
}

.btn-blue{
    color: #FFF;
    background: #374b9d;

    &:hover,
    &:focus{
        color: #FFF;
        background: darken(#374b9d, 10%);
    }
}

.br-0{
    border-radius: 0 !important;
}

.fz-9{
    font-size: 9px !important;
}

.fz-12{
    font-size: 12px !important;
}

.video-default{
    width: 500px;
    max-width:100%;
    position: relative;
    margin-left:auto;
    margin-right:auto;

    &:before{
        content:'';
        display: block;
        padding-bottom: (378 / 487) * 100%;
    }

    iframe{
        position: absolute; 
        width: 100%;
        height: 100%;

        top:0;
        left:0;
    }
}

.form-control{
    border-radius: 0;
    min-height: 44px;
    border-color: #ececec;

    font-size: 13px;
    color: #000;

    @include placeholdIt(){
        color: #ababab;
    }
}

.form-file{
    label,
    input{
        display: inline-block;
        vertical-align: middle;
    }

    label{
        margin-right: 15px;
        color: #ababab;
    }
}

textarea{
    resize: none;
}

.block-default{
    .item-default{
        .photo{
            position: relative;
            padding-bottom: (240/350)*100%;

            .picture{
                position: absolute;
                width: 100%;
                height: 100%;

                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        .dados{
            text-align: center;
            
            .nome{
                margin-top: 10px;
                font-family: "dosisbold";
                font-size: 19px;
                color: #374b9d;
            }
        }
    }
}

.equipe-default{
    .item-default{
        .photo{
            position: relative;
            padding-bottom: (235/255)*100%;

            .picture{
                position: absolute;
                width: 100%;
                height: 100%;

                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        .dados{
            text-align: center;
            
            .nome{
                margin-top: 10px;
                font-family: "dosisbold";
                font-size: 19px;
                color: #374b9d;
            }
        }
    }
}

.parceiro-default{
    max-width: 270px;
    .item-default{
        .photo{
            position: relative;
            padding-bottom: 100%;

            .picture{
                position: absolute;
                width: 100%;
                height: 100%;

                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        .dados{
            text-align: center;
            
            .nome{
                margin-top: 10px;
                font-family: "dosisbold";
                font-size: 19px;
                color: #374b9d;
            }
        }
    }
}

.contatos-vertical{
    padding-bottom: 40px;
    border-bottom: 4px solid #d6d6d6;
    
    bold{
        display: block;

        &:not(:first-child){
            margin-top: 30px;
        }
        
        font-family: "dosisbold";
        font-size: 17px;
        color: #333333;

        .icon{
            display: inline-block;
            vertical-align: middle;
            margin-right: 10px;
        }
    }

    span{
        font-family: "dosisregular";
        font-size: 16px;
        color: #999;
    }
}

.flx-row{
    display: flex;
    flex-wrap: wrap;

    &.justify-content-center{
        justify-content: center;
    }

    & > *{
        max-width: 100%;
    }
}

.internas{
    img[style]{
        max-width: 100%;
        height: auto !important;
    }
}