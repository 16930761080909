a{
	color:inherit;

	&:hover,
	&:focus{
		outline: none;
		text-decoration: none;
	}
}

.paginacao{
	text-align: center;
}

.title-internas{
	margin-top:0;
}

.page-header{
	border-bottom: none;
	
	h1{
		font-family: "dosisbold";
		font-size: 40px;
		color: #212121;
	}

	h2{
		font-family: "dosisbold";
		font-size: 21px;
		color: #1FABEB;
	}
}

.header-internas{
	padding: 110px 0 120px;
	margin-bottom: 60px;
	background-color: rgba(#000,0.4);
	background-blend-mode: multiply;
	background-position: center top;
	background-repeat: no-repeat;
	background-size: cover;

	h1{
		font-family: "dosisextrabold";
		font-size: 50px;
		color: #FFF;
	}

	.breadcrumbs{
		span,
		ul{
			display: inline-block;
			vertical-align: middle;

			font-size: 17px;
			color: #FFF;
		}

		ul{
			li{
				display: inline-block;
				font-family: "dosisregular";

				&:not(:last-child){
					&:after{
						content: "/";

						display: inline-block;
						vertical-align: middle;
						margin: 0 2.5px;
					}
				}
			}
		}
	}
}