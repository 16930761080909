.paginacao{
	margin-top:20px;
	margin-bottom:30px;

	> *{
		min-width: 40px;
		display: inline-block;
		padding:8px 16px;
		color: #FFF;
		border-radius: 4px;
		background: #374b9d;

	}

	.active,
	*:hover{
		text-decoration:none;
		color: #FFF;
		background: #ea624c;
	}
}
