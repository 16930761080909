.rodape{
	position: relative;
	padding-top: 30px;
	
	font-family: "dosisregular";
	font-size: 16px;
	color: #FFF;

	background: $bg-rodape;
	background-size: cover;

	p{
		margin-bottom: 2.5px;
	}

	nav{
		ul{
			text-align: center;

			li{
				display: inline-block;
				vertical-align: middle;
				margin: 0 8px;

				&:hover,
				&.active{
					a{
						color: #070707;
					}
				}
			}
		}
	}

	.creditos{
		position: relative;
		font-size: 14px;
		color: #585858;

		padding: 30px 0 35px;
		background: $bg-creditos;

		.logo-gv8{
			display: inline-block;
			vertical-align: middle;
			margin-left: 5px;
			margin-top: -2.5px;
		}

		.pager-up{
			position: absolute;
			bottom: calc(100% - 20px);
			left: calc(50% - 50px);

			display: flex;
			justify-content: center;
			align-items: flex-start;

			transform: translateY(50%);

			width: 100px;
			height: 100px;
			border-radius: 50%;

			background: #FFF;

			font-size: 30px;
			color: #C4DB62;
		}

		bold{
			font-family: "roboto_bolditalic";
		}
	}
}