.banners{
	position: relative;

	&:after{
		content:'';
		display: block;
		position: absolute;
		width: 100%;
		height: 22px;
		background: url(#{$imgs}/after-banners.png) center bottom repeat-x;
		z-index:10;
		left:0;
		bottom:0;

		@media (max-width: 500px){
			display: none;
		}
	}

	.owl-dots{
		position: absolute;
		top: 50%;
		left: calc(50% - 585px);

		transform: translateY(-50%);
		z-index: 19;

		.owl-dot{
			display: block;
			margin: 10px 0;

			width: 11px;
			height: 25px;
			border-radius: 50%;
			
			// border: none;
			background: #FFF;

			border-width: 5px;
			border-style: solid;
			border-color: transparent;

			&:focus{
				outline: none;
			}

			&.active{
				border-color: #FFDD00;
			}
		}
	}
}

.servicos{
	position: relative;
	padding-top: 50px;
	background: $bg-servicos;

	.servico-default{
		margin-bottom: 80px;
		
		.item-default{
			.photo{
				position: relative;
				padding-bottom: 130px;

				.picture{
					position: absolute;
					width: 100%;
					height: 100%;

					display: flex;
					justify-content: center;
					align-items: center;
				}
			}

			.dados{
				margin-top: 30px;
				text-align: center;
				
				.nome{
					font-family: "sweet_saltyregular";
					font-size: 50px;
					color: #6F4791;
					text-transform: uppercase;

					// margin-bottom: 40px;
				}

				.desc{
					font-family: "dosisregular";
					font-size: 16px;
					color: #878787;
					margin-bottom: 25px;

					@include resumo(16px, 1.4, 3);
				}

				a{
					font-family: "dosisregular";
					font-size: 16px;
					color: #212121;
				}
			}
		}
	}
}

.participar{
	position: relative;
	background: $bg-participar;
	background-size: cover;
	
	header{
		margin: 60px 0;

		h1{
			font-family: "dosisbold";
			font-size: 40px;
			color: #FFF;

			margin: 0 0 5px;
		}

		p{
			font-family: "dosisbold";
			font-size: 17px;
			color: #212121;
		}
	}
}

.depoimentos{
	position: relative;
	background: $bg-depoimentos;
	background-size: cover;

	.content-carousel{
		.owl-dots{
			text-align: center;
			margin: 15px 0;

			.owl-dot{
				display: inline-block;
				vertical-align: middle;
				
				border: none;
				background: transparent;

				&:before{
					content: "\f004";

					font-family: "FontAwesome";
					font-size: 18px;
					color: rgba(#FFF, .4);

					margin: 0 2.5px;
				}

				&:focus{
					outline: none;
				}

				&.active{
					&:before{
						color: #FFF;
					}
				}
			}
		}
	}

	.depoimento-default{
		.item-default{
			figure{
				margin: 50px 0 25px;
				
				img{
					display: inline-block;
				}
			}

			.depo{
				font-family: "dosisregular";
				font-size: 25px;
				color: #FFF;

				&:after{
					content: "";

					display: block;

					margin: 10px auto;

					width: 87px;
					height: 18px;

					z-index: 10;

					background: $after-depoimentos;
				}
			}

			.nome{
				text-align: center;
				font-family: "dosisbold";
				font-size: 18px;
				color: #2D2D2D;
			}
		}
	}
}


.sobre-nos{
	@media (min-width: $screen-md-min){
		.row{
			display: flex;
			flex-wrap: wrap;
		}

		.image-area{
			align-self: flex-end;
		}
	}
}