$imgs: '../imagens/estrutural';
$fonts: '../fonts';

$cor-default: rgba(#374b9d, .2);
$bg-topo: rgba(#00AFCF, .2);
$before-topo: url(#{$imgs}/before-topo.png) center top no-repeat;
$after-topo: url(#{$imgs}/after-topo.png) center top repeat-x;
$bg-servicos: url(#{$imgs}/bg-servicos.png) center top no-repeat;
$before-servicos: url(#{$imgs}/before-servicos.png) center top no-repeat;
$bg-participar: url(#{$imgs}/bg-participar.png) center top no-repeat;
$bg-depoimentos: url(#{$imgs}/bg-depoimentos.png) center top no-repeat;
$after-depoimentos: url(#{$imgs}/after-depoimentos.png) center top no-repeat;
$bg-rodape: url(#{$imgs}/bg-rodape.png) center top no-repeat;
$bg-creditos: rgba(#FFF, 1);
$bg-sprite: url(#{imgs}/sprite.png) no-repeat;